<template>
  <el-form
    ref="form"
    id="p2p-form"
    v-loading="isLoading"
    :model="p2pForm"
    :rules="rules"
    label-position="top"
    :hide-required-asterisk="isViewing"
    @submit.native.prevent="handleSubmit"
  >
    <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="stretch">
      <el-col :span="isMobile ? 24 : 12" class="card">
        <el-row type="flex" justify="space-between" align="middle">
          <h3><b>{{ leftHeaderTitle }}</b></h3>
          <div v-if="isViewing">
            <b>{{ $t("PRICE_PER_COIN") }}</b>: {{ currencyName }} {{ p2pForm.price }}
          </div>
        </el-row>
        <el-divider/>
        <el-form-item 
          :label="$t('NO_OF_COINS')"
          prop="availableCoins"
        >
          <el-input 
            :disabled="isEditing"
            v-model.number="p2pForm.availableCoins"
            type="number"
          />
        </el-form-item>
        <el-form-item
          v-if="!isViewing"
          :label="
            $t('PRICE_PER_COIN_IN_CURRENCY', {
              currency: currencyName,
            })
          "
          prop="price"
          class="is-required"
        >
          <el-input
            :disabled="isEditing || isViewing"
            v-model.number="p2pForm.price"
            type="number"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="isViewing"
          :label="$t('TRANSACTION_TOTAL_PRICE')"
        >
          <el-input
            :value="transactionTotalPrice"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item 
          v-if="isEditing" 
          :label="$t('STATUS')" 
          prop="status"
          class="status-dropdown"
        >
          <el-select
            :class="getP2PStatusClass(p2pForm.status) + (isMobile ? ' w-100': '')"
            v-model="p2pForm.status">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="$t(item.value)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item 
          v-if="isAdding" 
          :label="$t('REMARKS')" 
          prop="remarks" 
          class="remark-input"
        >
          <el-input
            v-model="p2pForm.remark"
            type="textarea"
            resize="none"
            :rows="3"
            :placeholder="$t( type === 'SELL' ? 'P2P_FOR_BUYER_REMARK_PLACEHOLDER' : 'P2P_FOR_SELLER_REMARK_PLACEHOLDER')"
          ></el-input>
        </el-form-item>

        <div class="action-button-wrapper">
          <el-button
            native-type="submit"
            class="button primary"
          >
            {{ submitButtonText }}
          </el-button>
        </div>
      </el-col>

      <el-col :span="isMobile ? 24 : 12" class="card">
        <el-row type="flex" justify="space-between" align="middle">
          <h3><b>{{ rightHeaderTitle }}</b></h3>
        </el-row>

        <el-divider/>

        <bank-details 
          :userObj="userObjToUse"
          fullyExtend
        />

        <post-details
          v-if="!isAdding"
          :post="p2pForm" 
          hideLabel hideRemark
          fullyExtend
        />

        <div v-if="isAdding" class="action-button-wrapper">
          <el-button
            class="button primary"
            @click="goToEditProfile(false)"  
            @auxclick.native="goToEditProfile(true)"  
          >
            {{ $t("EDIT_BANK_DETAILS") }}
          </el-button>
        </div>
        
        <el-form-item v-if="!isAdding" :label="$t('REMARKS')">
          <el-input
            :disabled="!isEditing"
            v-model="p2pForm.remark"
            type="textarea"
            resize="none"
            :placeholder="isViewing ? '-' : $t( type === 'SELL' ? 'P2P_FOR_BUYER_REMARK_PLACEHOLDER' : 'P2P_FOR_SELLER_REMARK_PLACEHOLDER')"
            :rows="3"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <page-footer
      :isLoading="isLoading"
      :handleBackTo="exit"
    />
  </el-form>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import i18n from "@/locales";
import BankDetails from '../components/bank-details.vue';
import PageFooter from "@/components/page-footer.vue";
import PostDetails from '../components/post-details.vue';
import { generalMixin } from "@/utils/general-mixin";
import { createPost, getPostById, updatePost, createTransaction } from "@/api/p2p-market.js";
import { mapGetters } from "vuex";
import { validateNum } from "@/utils/helpers"

const formFields = {
  initialCoin: 0,
  availableCoins: 0,
  price: 0,
  type: "BUY",
  currency: {
    id: 0,
  },
  remark: null,
};

const statusList = [
  {
    value: "OPEN",
    class: "status-active",
  },
  {
    value: "CLOSED",
    class: "status-inactive",
  },
  {
    value: "PAUSED",
    class: "status-pending",
  },
];

export default {
  props: {
    type: { type: String, required: true },
    currencyObj: { type: Object, required: true },
    formId: { type: String },
  },
  mixins: [generalMixin],
  components: {
    PageFooter, BankDetails, PostDetails
  },
  watch: {
    currencyObj(val) {
      this.p2pForm.currency.id = val.id;
    },
  },
  data() {
    return {
      p2pForm: cloneDeep(formFields),
      statusList: cloneDeep(statusList),
      isViewing: false, // buying/ elling coin
      isAdding: false, // create new request
      isEditing: false, // edit the request
      isLoading: false,
      isOwner: false,
      rules: {
        availableCoins: [
          {
            required: true,
            type: "number",
            min: 1,
            message: i18n.t("NO_OF_COINS_REQUIRED"),
            trigger: "blur",
          },
          { validator: this.validateNumber, trigger: 'blur' }
        ],
        price: [
          {
            required: true,
            message: i18n.t("PRICE_PER_COIN_IN_CURRENCY_REQUIRED"),
            trigger: "blur",
          },
          { validator: this.validateNumber, trigger: 'blur' }
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    leftHeaderTitle() {
      if (this.isOwner || !this.$route.params.id) {
        if (this.type === "BUY") {
          return this.$t("SET_YOUR_BUY")
          } else {
          return this.$t("SET_YOUR_SELL")
        }
      } else {
        if (this.type === "BUY") {
          return this.$t("BUY_VALUE")
        } else {
          return this.$t("SELL_VALUE")
        }
      }
    },
    rightHeaderTitle() {
      if (this.isOwner || !this.$route.params.id) {
          return this.$t("YOUR_DETAILS")
      } else {
        if (this.type === "BUY") {
          return this.$t("BUYING_FROM")
        } else {
          return this.$t("SELLING_TO")
        }
      }
    },
    submitButtonText() {
      if (this.isAdding || this.isEditing) {
        if (this.isEditing) {
          return this.$t("SAVE")
        } else {
          return this.$t("ADD")
        }
      } else {
        return this.$t(this.type)
      }
    },
    currencyName() {
      let name = this.currencyObj.code ? this.currencyObj.code.toUpperCase() : ''
      return name
    },
    userObjToUse() {
      if (this.isEditing || this.isAdding) {
        return this.user
      } else if (this.p2pForm.user) {
        return this.p2pForm.user
      } else {
        return null
      }
    },
    transactionTotalPrice() {
      const price = this.p2pForm.availableCoins ? (this.p2pForm.price * this.p2pForm.availableCoins) : 0

      return `${this.currencyName} ${price}`
    }
  },
  methods: {
    goToEditProfile(inNewTab = false) {
      this.$store.commit("p2p-market/REDIRECT_BACK_TO", this.$route.fullPath)
      this.goTo('/bo/profile', inNewTab)
    },
    validateNumber(_, value, callback) {
      if (validateNum(value) == false) {
        callback(this.$t("ONLY_NUMBER"))
      }
      callback();
    },
    getP2PStatusClass(status) {
      return this.statusList.find(el => el.value === status)?.class
    },
    exit() {
      this.goTo("/bo/p2p-market")
    },
    getFormDetail() {
      this.isLoading = true;
      getPostById(this.formId)
        .then((res) => {
          if (res.status == "ok") {
            this.p2pForm = cloneDeep(res.result);
            this.isOwner = this.p2pForm.user.id === this.user.id

            if (this.isOwner) this.isEditing = true;
            else this.initViewData();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    initViewData() {
      this.isViewing = true
      this.rules.availableCoins.push(
        {
          type: "number",
          max: this.p2pForm.initialCoin,
          message: i18n.t("TRANSACTED_COINS_CANNOT_MORE_THAN_INITIAL"),
          trigger: "blur",
        }
      )
    },
    handleSubmit() {
      console.log("valid.....");
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        if (this.isViewing) {
          this.handleAction()
          return
        }
        console.log("submitting.....");

        const postData = cloneDeep(this.p2pForm);
        console.log(postData);
        if (this.isAdding) this.handleCreate(postData);
        else if (this.isEditing) this.handleEdit(postData);
      });
    },
    handleCreate(postData) {
      this.isLoading = true;
      postData.initialCoin = postData.availableCoins
      delete postData.availableCoins
      createPost(postData)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("ADDED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
            this.goTo("/bo/p2p-market?chosenTab=your-request")
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleEdit(postData) {
      this.isLoading = true;
      let pData = {
        id: postData.id,
        status: postData.status,
        remark: postData.remark
      };
      // console.log(pData)
      updatePost(pData)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
            this.exit();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAction() {
      const postData = {
        buyer: {
          id: this.type == 'BUY' ? this.user.id : this.p2pForm.user.id,
        },
        seller: {
          id: this.type == 'SELL' ? this.user.id : this.p2pForm.user.id,
        },
        coin: this.p2pForm.availableCoins,
        post: {
          id: this.p2pForm.id,
        },
      };
      this.handleTransaction(postData);
    },
    handleTransaction(postData) {
      let textCode = "CONFIRM_P2P_BUY"
      if (this.type === "SELL") textCode = "CONFIRM_P2P_SELL"

      this.$confirm(this.$t(textCode, { amount: this.p2pForm.availableCoins }), this.$t("ARE_YOU_SURE"))
      .then(() => {
        // console.log(postData);
        this.isLoading = true;
        createTransaction(postData)
          .then((res) => {
            console.log(res)
            if (res.status == "ok") {
              this.goTo('/bo/p2p-market/transaction/'+res.result.transactionRef)
            }
          })
          .catch(this.handleError)
          .finally(() => {
            this.isLoading = false;
          });
      })

    },
  },
  mounted() {
    if (this.formId) {
      this.getFormDetail();
    } else {
      this.isAdding = true;
      this.p2pForm.type = this.type;
      this.isOwner = this.p2pForm?.user?.id === this.user?.id
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
#p2p-form {
  .card {
    position: relative;
    background-color: $white;
    border-radius: 10px;
    padding: 2rem 1.5rem;
    &:first-child {
      margin-right: 1rem;
    }
  }

  .el-divider--horizontal {
    margin: 1rem 0;
  }

  .el-form-item {
    display: grid;
    // height: 80px;
    vertical-align: middle;
    align-items: center;

    label {
      font-size: 0.8rem;
      color: #253858;
      padding: 0;
    }
    .el-input__inner, .el-textarea__inner {
      border-color: #f0f0f0;
      color: #767396;
      font-size: 0.9rem;
    }
    .el-input.is-disabled .el-input__inner, .el-textarea.is-disabled .el-textarea__inner {
      background-color: transparent;
      border: none;
      padding-left: 0;
      color: unset;
    }

    &.remark-input,
    &.status-dropdown {
      margin-bottom: 4rem;
    }
  }
  
  
  .action-button-wrapper {
    width: calc(100% - 1.5rem - 1.5rem);
    margin: 0 auto;
    position: absolute;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    >.el-button {
      min-width: 30%;
      margin: auto;
    }
  }
  
  .bank-details {
    margin-bottom: 4rem;
    .label {
      margin-bottom: 0;
      font-size: 0.8rem;
      color: #253858;
      line-height: 36px;
    }
    span {
      color: #767396;
      font-size: 0.9rem;
    }
  }

  .post-details {
    margin-bottom: 4rem;
  }
}
.mobile #p2p-form {
  >.el-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .card {
    height: auto;
    margin: auto;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
  }
  .el-form-item {
    margin-bottom: 1rem;
  }
  .bank-details,
  .post-details {
    margin-bottom: 1rem;
  }
}
</style>