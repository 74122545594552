<template>
  <div class="post-details">
    <el-tag v-if="!hideLabel" class="post-details-tag">
      {{ $t("POST_DETAIL") }}
    </el-tag>

    <template v-if="post.remark && !hideRemark">
      <div class="label">{{ $t("REMARK") }}:</div>
      {{ post.remark }}

      <el-divider />
    </template>

    <el-row v-if="post" :type="isMobile ? '':'flex'" justify="space-between" align="middle" :gutter="40">
      <el-col>
        <div class="label">{{ $t("AVAILABLE_COIN") }}:</div>
        <span>{{ post.initialCoin }}</span>
      </el-col>
      <el-col>
        <div class="label">{{ $t($t('PRICE_PER_COIN_IN_CURRENCY', { currency: currency })) }}:</div>
        <span>{{ post.price }}</span>
      </el-col>
      <el-col>
        <div class="label">{{ $t("MEMBER_NAME") }}:</div>
        <span>{{ getFullName(post.user) }}</span>
      </el-col>
      <el-col v-if="!fullyExtend">
        <!-- Leave blank purposely -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js";

export default {
  name: "PostDetails",
  mixins: [generalMixin],
  props: {
    post: { type: Object, required: true },
    hideLabel: { type: Boolean, default: false },
    hideRemark: { type: Boolean, default: false },
    fullyExtend: { type: Boolean, default: false },
  },
  computed: {
    currency() {
      return this.post?.currency?.code?.toUpperCase() || ''
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

.post-details {
  .post-details-tag {
    border-radius: 20px;
    background-color: $white;
    color: #767396;
    border: 1px solid #767396;
    font-size: 16px;
    font-weight: bold;
  }
  .label {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #253858;
    line-height: 36px;
  }
  span {
    color: #767396;
    font-size: 0.9rem;
  }
}

.mobile {
  .post-details {
    .el-tag {
      font-size: 14px;
    }
    .el-col {
      margin-bottom: 0.5rem;
    }
  }
}

</style>